<template>
  <div class="tip-div">
    <span :style="{ fontSize: fontSize, color: color }">
      <i class="el-icon-info"></i>
      {{ content }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: () => ''
    },
    fontSize: {
      type: String,
      default: () => undefined
    },
    color: {
      type: String,
      default: '#1890FF'
    }
  }
}
</script>

<style scoped>
.tip-div {
  font-size: 12px;
}
</style>
